import {
  loadDefaultJapaneseParser
} from 'budoux';

export default () => {
  const parser = loadDefaultJapaneseParser();
  const target = document.querySelectorAll('[data-japanese-parser]');
  if (target) {
    for (let i = 0; i < target.length; i++) {
      parser.applyElement(target[i]);
    }
  }
};